import clsx from 'clsx'

function Info({
  name,
  children,
  invert = false,
}: {
  name: string
  children: React.ReactNode
  invert?: boolean
}) {
  return (
    <span
      className={clsx(
        'text-sm not-italic',
        invert ? 'text-neutral-300' : 'text-neutral-600',
      )}
    >
      <strong className={invert ? 'text-white' : 'text-neutral-950'}>
        {name}
      </strong>
      <br />
      {children}
    </span>
  )
}

export function RelevantInfo({
  invert = false,
  ...props
}: React.ComponentPropsWithoutRef<'ul'> & { invert?: boolean }) {
  return (
    <ul role="list" {...props}>
      <li>
        <Info name="Advogada" invert={invert}>
          Danielly Fialho
          <br />
          OAB/SP 503164
          <br />
          Atendimento online em todo o Brasil e presencial em São Paulo e Mogi
          das Cruzes
        </Info>
      </li>
      <li>
        <Info name="Contato" invert={invert}>
          (21) 98572-1197
          <br />
          (11) 98987-8041
          <br />
          juridico@daniellyfialho.com
        </Info>
      </li>
    </ul>
  )
}
